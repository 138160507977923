<template>
  <div class="dropdown dropdown-end" :blur="$emit('onChange', selected)">
    <d-input tabindex="0" class="m-1" :value="displayText" readOnly size="xs" />

    <div tabindex="0" class="f-select-content">
      <div class="w-full flex gap-2 items-center">
        <d-input
          :placeholder="$t('Filter')"
          class="w-5/6"
          v-model="filter"
          size="xs"
        />
        <d-icon
          name="refresh"
          size="sm"
          class="cursor-pointer ml-auto"
          @click="selected = []"
        />
      </div>
      <div class="h-64 overflow-y-scroll mt-2">
        <div
          class="text-xs p-2 hover:bg-base-300 rounded-md"
          v-for="account of accounts"
          :key="account._id"
        >
          <div class="w-full flex">
            <input
              type="checkbox"
              class="checkbox border-solid checkbox-sm"
              :id="`${account._id}.account`"
              :value="account._id"
              v-model="selected"
            />
            <label :for="`${account._id}.account`" class="ml-2 w-44">
              <span class="item-text">{{ account.name }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AccountFilter',

  data: () => ({
    filter: '',
    selected: [],
  }),

  computed: {
    ...mapGetters({
      credentials: 'credentials/credentials',
    }),

    displayText() {
      if (this.selected.length < 1) {
        return this.$t('Select accounts')
      }
      return `${this.$t('Select accounts')} (${this.selected.length})`
    },

    accounts() {
      return this.credentials.filter(
        (el) => !this.filter || el.name.includes(this.filter)
      )
    },
  },
}
</script>
