const ONE_MINUTE = 60e3
const ONE_DAY = 24 * 60 * ONE_MINUTE

const ORDER_STATUS = Object.freeze({
  OPEN: 1,
  CLOSED: 2,
  CLOSING: 3,
  CANCELLED: 4,
  INVALID: 90,
})

const ORDER_SIDES = {
  BUY: 1,
  SELL: 2,
}

const SYMBOLS_BY_EXCHANGE = {
  bybit_copy: ['BTCUSDT', 'ETHUSDT', 'GMTUSDT', 'AVAXUSDT', 'ETCUSDT'],
  binance_coin_m: ['BTCUSD_PERP'],
  binance_coin_s: ['BTCUSDT'],
  cci: ['BTCUSDT'],
  bitcopy: ['BTCUSD'],
}

const EXCHANGE_NAMES = {
  bybit: 'BYBIT',
  bybit_copy: 'BYBIT Copy',
  binance_coin_m: 'BINANCE COIN-M Futures',
  binance_coin_s: 'BINANCE USDⓈ-M Futures',
  cci: 'Crypto Copy Invest',
  bitcopy: 'BITCOPY',
  paper: 'PAPER',
  omtrader: 'OMTRADE',
}

const EXCHANGE_OPTIONS = [
  { value: 'bybit', label: 'BYBIT (Lot: USD)' },
  { value: 'bybit_copy', label: 'BYBIT (Copy - Lot: Coin)' },
  {
    public: true,
    value: 'binance_coin_m',
    label: 'BINANCE COIN-M Futures (Lot: USD)',
  },
  {
    public: true,
    value: 'binance_coin_s',
    label: 'BINANCE USDⓈ-M Futures (Lot: Coin)',
  },
  { value: 'bitcopy', label: 'BITCOPY (Lot: USD)' },
  { public: true, value: 'paper', label: 'PAPER' },
  { public: true, value: 'omtrader', label: 'OMTRADER (Lot: Coin)' },
]

const EXCHANGES = EXCHANGE_OPTIONS.reduce((acc, el) => {
  acc[el.value] = el.label
  return acc
}, {})

const DIRECT_ORDER_TYPE_OPTIONS = ['MARKET', 'STOP', 'LIMIT'].map(
  (value, index) => ({
    value: index + 1,
    label: value,
  })
)

const DIRECT_ORDER_SPAN_OPTIONS = [
  { label: '30s', value: 30e3 },
  { label: '1 min', value: 60e3 },
  { label: '2 min', value: 120e3 },
  { label: '5 min', value: 300e3 },
  { label: '10 min', value: 600e3 },
  { label: '15 min', value: 900e3 },
  { label: '1 hour', value: 3600e3 },
]

const BUY_SELL_OPTIONS = [
  { label: 'BUY', value: ORDER_SIDES.BUY },
  { label: 'SELL', value: ORDER_SIDES.SELL },
]

module.exports = {
  EXCHANGES,
  EXCHANGE_NAMES,
  EXCHANGE_OPTIONS,
  SYMBOLS_BY_EXCHANGE,
  DIRECT_ORDER_SPAN_OPTIONS,
  DIRECT_ORDER_TYPE_OPTIONS,
  BUY_SELL_OPTIONS,
  ONE_MINUTE,
  ONE_DAY,
  LOGOUT_KEY: '$$LOGOUT',
  TOKEN_KEY: 'mts-token',
  SOUND_WINDOW_ID: 'SOUND_WINDOW_ID',
  SOUND_WINDOW_END: 'SOUND_WINDOW_END',
  SOUND_VOLUME: 'SOUND_VOLUME',
  SOUND_PRICE_SPAN: 'SOUND_PRICE_SPAN',
  ORDER_STATUS,
  ORDER_SIDES,
}
